import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': process.env.VUE_APP_URL,
        'Access-Control-Allow-Credentials': "true",
        'Access-Control-Max-Age': "1800",
        'Access-Control-Allow-Headers': "origin, content-type, accept"
    },
});

// Interceptor để thêm access_token vào params
apiClient.interceptors.request.use(
    config => {
        const access_token = localStorage.getItem('access_token');
        const expires_at = new Date().getTime() + (3600 * 1000);
        localStorage.setItem('expires_at', expires_at);
        if (access_token && expires_at) {
            const current_time = new Date().getTime();
            if (current_time > parseInt(expires_at, 10)) {
                this.$gAuth.signOut();
                localStorage.clear();
                this.user = "";
                this.$router.push({path: "/auth/login"});
            } else {
                config.headers.Authorization = access_token;
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default apiClient;
