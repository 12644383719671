import apiClient from "@/services/api.js";
import { auth } from "@/services/store";
export default function authMiddleware(next) {
  const email = auth().email;
    if (!email) {
        next("/auth/login");
    }

  apiClient.post("/auth/verify", { email }).then((response) => {
    if (response && !response.data.error_code) {
        next();
    }else{
        next("/auth/login");
    }
  }).catch(response => {
      if (response.status === 403) {
          alert(response.response.message || "Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại!");
          next("/auth/login");
          return;
      }
      console.log('response', response);
      alert("Đã có lỗi xảy ra!");
  });
}
