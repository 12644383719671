<template>
  <div>
    <div v-if="activeForm" class="main-form-modals">
      <div class="relative pt-1" v-if="showNotice">
      <div class="flex mb-2 items-center justify-between">
        <div class="text-right">
          <span class="text-xs font-semibold inline-block text-emerald-600">
            {{ progress }}%
          </span>
        </div>
      </div>
      <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-emerald-200">
        <div :style="{ width: `${progress}%` }" class="progress-bar"></div>
      </div>
    </div>
      <div class="relative w-auto my-6 mx-auto max-w-sm form-modals">
        <div v-if="showNotice && !messErr" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500"
          style="z-index: 9999;top: 28px; width: 100%; margin: auto;">
          <span class="inline-block align-middle mr-8">
            <b class="capitalize"></b>{{ messageNotice }}
          </span>
        </div>

        <div v-if="showNotice && messErr" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500"
          style="z-index: 9999;top: 28px; width: 100%; margin: auto;">
          <span class="inline-block align-middle mr-8">
            <b class="capitalize"></b>{{ messageNotice }}
          </span>
        </div>
        <!--content của form thêm mới-->
        <div v-if="typeForm === 'create'"
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none blur-form">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Thêm mới thông tin Admin
            </h6>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="toggleModal">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <div class="flex flex-wrap p-4">
                <div class="w-full px-3">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Tên Admin
                    </label>
                    <input type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="formData.name" placeholder="Điền tên tài khoản" style="margin-bottom: 6px;" :maxlength="maxCharacters" @input="removeSpaces"/>
                      <span v-if="v$.formData.name.$error" style="color:red"> {{ v$.formData.name.$errors[0].$message }} </span>
                  </div>
                </div>
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Email
                    </label>
                    <input type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="formData.email" placeholder="Email" style="margin-bottom: 6px;"/>
                      <span v-if="v$.formData.email.$error" style="color:red"> {{ v$.formData.email.$errors[0].$message }} </span>
                  </div>
                </div>
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Trạng thái
                    </label>
                    <select name="selectStatus" v-model="formData.is_active"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                      <option value="1">Kích hoạt</option>
                      <option value="0">Ngừng kích hoạt</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b p-4">
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" @click="toggleModal">
              Đóng
            </button>
            <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" v-on:click="save(idData)">
              Đồng ý
            </button>
          </div>
        </div>

        <!--content của form Xóa-->
        <div v-if="typeForm === 'delete'"
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none blur-form">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Thông báo
            </h6>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="toggleModal">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="flex-auto px-4 lg:px-10 py-2 pt-2">
            <h6 class="text-black text-sm mt-3 mb-6 font-bold uppercase">Bạn có chắc chắn muốn xóa thông tin này không ?</h6>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b p-4">
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" @click="toggleModal">
              Hủy
            </button>
            <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" @click="deleteData(idData)">
              Đồng ý
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeForm" class="opacity-25 fixed inset-0 z-40 bg-black" id="small-modal-id-backdrop"></div>
  </div>
</template>
<style>
.form-modals {
  width: 100%;
  z-index: 999;
}

.main-form-modals {
  width: 36%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blur-form{
  --tw-shadow: 0 10px 25px -3px rgb(0 0 0 / 70%), 0 4px 6px -2px rgb(0 0 0 / 0%)!important;
}

.progress-bar {
    background-color: #68d391;
    /* Màu sắc thanh tiến trình */
    height: 100%;
    /* Chiều cao của thanh tiến trình */
    width: 0;
    /* Bắt đầu với chiều rộng là 0 */
    animation: progressAnimation 5s infinite;
    /* Sử dụng animation keyframes */

}
.b-out{
  background: #e3e5e7;
}

@keyframes progressAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
<script>
import apiClient from '@/services/api.js';
import useValidate from '@vuelidate/core'
import { required, minLength, helpers, email } from '@vuelidate/validators'

const alphaValidator = value => /^[a-zA-Z0-9àáảãạăắằẳẵặâấầẩẫậèéẻẽẹêếềểễệđìíỉĩịòóỏõọôốồổỗộơớờởỡợùúủũụưứừửữựỳýỷỹỵ ]+$/.test(value);
export default {
  name: "small-modal",
  components: {

  },

  data() {
    return {
      v$: useValidate(),
      showModal: false,
      myValue: '',
      formData:{
        name:'',
        email: '',
        is_active: 0
      },
      showNotice: false,
      messageNotice: '',
      messErr: false,
      progress: 0,
      maxCharacters: 50
    }
  },

  props: {
    activeForm: {
      type: Boolean, // kiểu dữ liệu của prop
      default: false, // giá trị mặc định nếu prop không được truyền vào
    },
    typeForm: {
      type: String, // kiểu dữ liệu của prop
      default: 'create'
    },
    idData: {
      type: Number, // kiểu dữ liệu của prop
    },
  },

  created(){
    if(this.idData){
      this.showMerchant(this.idData);
    }
  },

  methods: {
    removeSpaces() {
      // Loại bỏ dấu cách từ giá trị đầu vào
      this.inputText = this.inputText.replace(/\s/g, "");
    },

    toggleModal() {
      this.$emit('update:activeForm', false);
    },

    save(idData) {
      this.v$.$validate()
      if (this.v$.$error) {
        return false;
      }// checks all inputs
      if(idData){
        apiClient.post('api/cms/admin/update/'+ idData, this.formData)
        .then(response => {
          if (response.data.error_code == 1) {
            this.messageNotice = 'Update thất bại. Vui lòng thử lại sau!';
            this.messErr = true;
          } else {
            this.messageNotice = response.data.message;
            this.showNotice = true;
            setInterval(this.updateProgress, 200);
          }
        })
        .catch(error => {
          console.error('Đã có lỗi xảy ra!', error);
        });
      }else{
        apiClient.post('api/cms/admin/create', this.formData)
        .then(response => {
          if (response.data.error_code == 1) {
            this.messageNotice = 'Thêm mới thất bại. Vui lòng thử lại sau!';
            this.messErr = true;
          } else {
            this.messageNotice = response.data.message;
            this.showNotice = true;
            setInterval(this.updateProgress, 200);
          }
          // this.$emit('update:activeForm', false);
        })
        .catch(error => {
          console.error('Đã có lỗi xảy ra!', error);
        });
      }
    },
    //Lấy chi tiết thông tin merchant
    showMerchant(id){
      apiClient.get('api/cms/admin/show/' + id)
        .then(response => {
          if (response.data.error_code == 1) {
            this.messageNotice = 'Có lỗi xảy ra. Vui lòng thử lại sau!';
            this.messErr = true;
          } else {
            this.formData = response.data.data;
          }
          // this.$emit('update:activeForm', false);
        })
        .catch(error => {
          console.error('Đã có lỗi xảy ra!', error);
        });
    },

    updateProgress() {
      // Tăng giá trị progress
      this.progress += 10;
      // Nếu progress vượt quá 100%, đặt lại về 0
      if (this.progress >= 100) {
        window.location.reload();
      }
    }
  },

  validations() {
    return {
      formData: {
        name: {
          required: helpers.withMessage('Vui lòng nhập tên admin', required),
          minLength: helpers.withMessage('Vui lòng nhập từ 2->50 ký tự', minLength(2)),
          alphaValidator: helpers.withMessage('Chỉ chấp nhận ký tự chữ cái', value => alphaValidator(value)),
        },
        email: {
          required: helpers.withMessage('Vui lòng nhập email', required),
          email: helpers.withMessage('Định dạng email không đúng', email)
        },
      },
    }
  },

}

</script>
